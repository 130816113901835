import React, { useState, useEffect, useContext } from 'react'
import { ErrorBelowInputField } from './elements/Errors'
import { InputText, InputRadio } from './elements/Inputs'
import SubmitButton, { ButtonFontSize } from './elements/SubmitButton'
import TrackingResultsModal from '../home/TrackingResultsModal'
import { TrackingFormContext } from '../context/TrackingFormContext'
import { useRouter } from 'next/router'
import { event } from '@/utils/gtag'
import { useSession } from 'next-auth/react'
import { retryAxiosInstance, globalConfig } from '@/types/retry'
import { removeLeadingZeros } from '@/libs/helpers'

const TrackingForm: React.FunctionComponent = () => {
  const [proType, setProType] = useState('N')
  const [tracking, setTracking] = useState('')
  const [loading, setLoading] = useState(false)
  const [serializedFormData, setSerializedFormData] = useState('')
  const { trackingFormApiResponse, setTrackingFormApiResponse } =
    useContext(TrackingFormContext)
  const [isTrackingModalOpen, setIsTrackingModalOpen] = useState<boolean>(false)
  const router = useRouter()
  const { status } = useSession()
  const regex = /^[0-9]*$/

  useEffect(() => {
    setTracking('')
  }, [proType])

  useEffect(() => {
    setTracking('')
  }, [proType])

  const formData = {
    pronumber: tracking,
    protype: proType,
  }

  const submitForm = async () => {
    setLoading(true)
    {
      if (!regex.test(tracking)) {
        setLoading(false)
        setTrackingFormApiResponse({
          errors: 'Y',
          bolermsg: `Please enter ${proType === 'N' ? 8 : 15} digit number`,
        })
        return
      }
    }
    const serializedData = new URLSearchParams(formData).toString()

    try {
      if (process.env.SENTRY_ENV === 'production')
        event({
          action: 'Tracking Form Submitted',
          category: 'Tracking',
          label: 'Tracking Form',
          value: status,
        })

      const response = await retryAxiosInstance.post(
        `/api/track-shipment`,
        serializedData,
        {
          ...globalConfig,
          timeout: 5000, // Set a timeout of 5 seconds
        }
      )

      const json = response.data
      setSerializedFormData(serializedData)
      setTrackingFormApiResponse(json)
      if (json.errors === 'N') {
        const cleanTrackingNumber = removeLeadingZeros(tracking)
        router.push({
          pathname: `/portal/account/shipments/${cleanTrackingNumber}`,
          query: { nptproNumber: cleanTrackingNumber },
        })
      } else {
        setLoading(false)
        if (json.bolermsg !== 'Invalid NPT pro number') {
          throw new Error(
            `IBMi failed to fetch at api/track-shipment: ${response.data}.`
          )
        }
      }
    } catch (error: any) {
      setLoading(false)
      setTrackingFormApiResponse({
        errors: 'Y',
        bolermsg:
          'Unable to track shipment at this time. Please try again later.',
      })
      console.error('api/track-shipment Failed after 3 attempts')
      console.log(error)
    }
  }

  return (
    <section>
      <form
        onSubmit={(event) => {
          event.preventDefault()
          submitForm()
        }}
      >
        <div className="items-end mt-3 md:mt-6 md:flex md:space-x-4">
          <div className="flex-1">
            <InputText
              labelText={`${proType === 'N' ? 8 : 15} Digit Pro Number`}
              type="text"
              placeholder="Add number"
              value={tracking}
              characterMax={proType === 'N' ? 8 : 15}
              onChange={(event: any) => {
                setTrackingFormApiResponse({})
                setTracking(event.target.value)
              }}
            />
          </div>

          <div className="pt-3 md:pt-0 md:-mb-1">
            <InputRadio
              labelText="NPT Pro Number"
              isChecked={proType === 'N'}
              onClick={() => {
                setProType('N')
                setTrackingFormApiResponse({})
              }}
              value={proType}
            />

            <InputRadio
              labelText="Interline Pro Number"
              isChecked={proType === 'I'}
              onClick={() => {
                setProType('I')
                setTrackingFormApiResponse({})
              }}
              value={proType}
            />
          </div>
        </div>

        {trackingFormApiResponse.errors === 'Y' && (
          <ErrorBelowInputField
            errorMessage={trackingFormApiResponse.bolermsg}
          />
        )}

        <div className="mt-3 md:mt-6">
          <p className="mb-3 text-sm md:mb-5 md:text-nptBase">
            <a
              href="/login"
              className="underline text-blue hover:no-underline"
              target="_blank"
              rel="noreferrer"
            >
              Sign in
            </a>{' '}
            to your customer account in order to view your Images, Documents,
            and Account Details.
          </p>
          <p className="mb-3 text-sm md:mb-5 md:text-nptBase">
            Or&nbsp;
            <a
              href="/register"
              className="underline text-blue hover:no-underline"
              target="_blank"
              rel="noreferrer"
            >
              create an account
            </a>{' '}
            and you will be up running in no time.
          </p>
        </div>

        <SubmitButton
          text={loading ? 'Loading...' : 'Track Shipment'}
          fontSize={ButtonFontSize.small}
          isDisabled={tracking ? (loading ? true : false) : true}
        />
      </form>

      {trackingFormApiResponse.errors === 'N' && isTrackingModalOpen && (
        <TrackingResultsModal
          isTrackingModalOpen={isTrackingModalOpen}
          setIsTrackingModalOpen={setIsTrackingModalOpen}
          apiResponse={trackingFormApiResponse}
          serializedFormData={serializedFormData}
        />
      )}
    </section>
  )
}

export default TrackingForm
