export const capitalizeFirstLetterOfEachWord = (str: string) => {
  return str
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ')
}

export const removeLeadingZeros = (value: string): string => {
  if (!value) throw new Error('Value is required')
  while (value.charAt(0) === '0') {
    value = value.slice(1)
  }

  return value
}
